@font-face {
  font-family: "sd";
  src:
    local("Super Dream"),
    url(./sd.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "sd";
  color: #ffeda3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2865c;
  font-size: min(4vw, 4vh);
  line-height: 50%;
}

.main {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}

.second {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}

.ground {
  top: 22vh;
  position: fixed;
  height: 100vh;
  width: 200vw;
  background-color: #457b27;
  border-radius: 50%;
  display: inline-block;
  z-index: -1;
  rotate: 1deg;
  animation: move-up 0.5s;
  border: 0.1px solid black;
}

.sun {
  top: 5vh;
  right: 15vw;
  position: fixed;
  height: 35vh;
  width: 35vh;
  background-color: #f29886;
  border-radius: 50%;
  display: inline-block;
  z-index: -2;
  animation: move-up-loads 0.75s linear 0s normal forwards;
}

.sun2 {
  top: 4vh;
  right: calc(15vw - 2vh);
  position: fixed;
  height: 37vh;
  width: 37vh;
  background-color: #f6c334;
  border-radius: 50%;
  display: inline-block;
  z-index: -3;
  animation:
    grow-in 0.25s ease-out 1s normal both,
    fade-in 0.75ms linear 1.5s;
}

.birds {
  width: min(11vw, 20vh);
  position: fixed;
  top: 4vh;
  left: 3vw;
  opacity: 0;
  animation:
    fade-in 0.5s linear 1.25s normal forwards,
    move-up 0.5s linear 1.25s;
  z-index: -100;
}

.tent {
  z-index: 2;
  width: 25vw;
  position: fixed;
  top: calc(89vh - 25vw);
  left: 4vw;
  opacity: 0;
  animation: pop-in 0.25s linear 1.25s normal forwards;
  -webkit-filter: drop-shadow(0.01px 0.01px 0 black)
    drop-shadow(-0.01px 0.01px 0 black) drop-shadow(0.01px -0.01px 0 black)
    drop-shadow(-0.01px -0.01px 0 black);
  filter: drop-shadow(0.01px 0.01px 0 black) drop-shadow(-0.01px 0.01px 0 black)
    drop-shadow(0.01px -0.01px 0 black) drop-shadow(-0.01px -0.01px 0 black);
}

.tent2 {
  z-index: 2;
  width: 13vw;
  position: fixed;
  top: 31vh;
  right: 4vw;
  opacity: 0;
  animation: pop-in 0.25s linear 1.5s normal forwards;
  -webkit-filter: drop-shadow(0.01px 0.01px 0 black)
    drop-shadow(-0.01px 0.01px 0 black) drop-shadow(0.01px -0.01px 0 black)
    drop-shadow(-0.01px -0.01px 0 black);
  filter: drop-shadow(0.01px 0.01px 0 black) drop-shadow(-0.01px 0.01px 0 black)
    drop-shadow(0.01px -0.01px 0 black) drop-shadow(-0.01px -0.01px 0 black);
}

.txt {
  background-color: #95c157;
  border-radius: 2vh;
  width: 110%;
  border: 4px solid #f6c334;
  text-shadow:
    0 0 1px black,
    0 0 1px black;
  opacity: 0;
  animation: pop-in-vertical 0.25s linear 0.75s normal forwards;
  z-index: 50;
}

.sparkford {
  position: fixed;
  top: 15vh;
  left: 15vw;
  width: min(30vw, 30vh);
  z-index: 1;
  animation:
    drop-in 0.75s ease-in-out 1.5s normal backwards,
    squishY 0.3s linear 2.2s normal forwards;
  -webkit-filter: drop-shadow(0.01px 0.01px 0 black)
    drop-shadow(-0.01px 0.01px 0 black) drop-shadow(0.01px -0.01px 0 black)
    drop-shadow(-0.01px -0.01px 0 black);
  filter: drop-shadow(0.01px 0.01px 0 black) drop-shadow(-0.01px 0.01px 0 black)
    drop-shadow(0.01px -0.01px 0 black) drop-shadow(-0.01px -0.01px 0 black);
}

.clickedBtn {
  border: 2px solid #E2865C;
  background-color: #FFEDA3;
  border-radius: 1vh;
  padding: min(4vh, 4vw);
  margin-bottom: min(4vh, 4vw);
  color: #F29886;
  cursor: pointer;
  z-index: 1000;
  animation: wiggle 0.4s
}

.btn {
  border: 2px solid #E2865C;
  background-color: #FFEDA3;
  border-radius: 1vh;
  padding: min(4vh, 4vw);
  margin-bottom: min(4vh, 4vw);
  color: #F29886;
  cursor: pointer;
  z-index: 1000;
}

.btn:hover {
  border: 2px solid #E2865C; 
  outline: 2px solid #F29886;
  background-color: #F6C334;
}

@keyframes wiggle {
  0% {
  transform: rotate(0deg);
  }
  25% {
  transform: rotate(5deg);
  }
  75% {
  transform: rotate(-5deg);
  }
  100% {
  transform: rotate(0deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0);
  }
}

@keyframes spin-grow-in {
  from {
    transform: scale(0%) rotate(45deg);
  }
  to {
    transform: scale(100%) rotate(0);
  }
}

@keyframes spin-grow-in-backwards {
  from {
    transform: scale(0%) rotate(-45deg);
  }
  to {
    transform: scale(100%) rotate(0);
  }
}

@keyframes grow-in {
  from {
    transform: scale(0%);
  }
  to {
    transform: scale(100%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes move-up-loads {
  from {
    transform: translateY(1000%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes squishY {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.9);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes drop-in {
  from {
    transform: translateY(-500%);
  }
  to {
    transform: translateY(0%);
  }
  0% {
    transform: translateY(-500%);
  }
  60% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  1% {
    opacity: 100%;
    transform: scaleX(0);
  }
  50% {
    opacity: 100%;
    transform: scaleX(1.2);
  }
  0% {
    opacity: 100%;
    width: scaleX(1);
  }
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes pop-in-vertical {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  1% {
    opacity: 100%;
    transform: scaleY(0);
  }
  50% {
    opacity: 100%;
    transform: scaleY(1.2);
  }
  0% {
    opacity: 100%;
    width: scaleY(1);
  }
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}
